import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Cps as cps } from '../model';


@Injectable({ providedIn: 'root' })
export class MonthlyFinalService {
  constructor(private http: HttpClient) { }

getWholeMonthlyfinalData(PersonID,Month,Year) {
  return this.http.get<any>(`${environment.apiUrl}/wholeMonthlyFinalData/${PersonID}/`+Month+"/"+Year);
}

getMonthlyFinal(PersonID, Month,Year) {
  return this.http.get<any>(`${environment.apiUrl}/monthlyFinal/${PersonID}/`+Month+"/"+Year);
}
}
