import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { User, EmployeeRoles } from "../model";

import { AlertService } from './alert.service';
import { OAuthSettings } from '../../../oauth';
import { MsalService } from "@azure/msal-angular";
import { GraphService } from "./graph.service";
import { EmployeeService } from "./employee.service";
import { LoginService } from "./login.service";
import { Employee } from "../model/employee";
import { Router } from "@angular/router";
import { jsonpFactory } from "@angular/http/src/http_module";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private currentEmployeeSubject: BehaviorSubject<Employee>;
  public currentEmployee: Observable<Employee>;

  private currentRoleSubject: BehaviorSubject<EmployeeRoles>;
  public currentRoles: Observable<EmployeeRoles>;

  public azureLoginSuccess: boolean;
  public static tokenA;
  currentroles;
  RoleIDs;
  public profile;

  constructor(
    private loginService: LoginService,
    private http: HttpClient, 
    private authService: MsalService,
    private employeeService: EmployeeService, 
    private alertsService: AlertService,
    private router: Router, 
    graphService: GraphService) 
    {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.azureLoginSuccess = false;          
      

    this.currentRoleSubject = new BehaviorSubject<EmployeeRoles>(JSON.parse(sessionStorage.getItem('currentRoles')));
    this.currentRoles = this.currentRoleSubject.asObservable();

    
    this.currentEmployeeSubject = new BehaviorSubject<Employee>(JSON.parse(sessionStorage.getItem('actualEmployee')));
    this.currentEmployee = this.currentEmployeeSubject.asObservable(); 
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentEmployeeValue(): Employee {
    return this.currentEmployeeSubject.value;
  }

  public get currentRolesValue(): EmployeeRoles {
    return this.currentRoleSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/users/authenticate`, {
        email: email,
        password: password
      })
      .pipe(
        map(user => {
          if (user) {
            ("login normal")
            if(user.AccountType == 'bewerber'){
              this.loginService.setUserLoggedIn(true);
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            }
            else{
              // store user details
            this.loginService.setUserLoggedIn(true);
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);

            this.employeeService.getById(user.PersonID).subscribe(employee=>{
              sessionStorage.setItem('actualEmployee',JSON.stringify(employee[0]));   
            });
            }
          }
          return user;
        })
      );
          
  }

  logout() {
    // remove user from local storage to log user out
    if (this.currentUserValue.PersonType) {
      if(this.currentUserValue.PersonType === 'intern' || this.currentUserValue.PersonType === 'admin') {
        this.azureLoginSuccess = false;
        sessionStorage.clear();
        this.authService.logout();
      }
    }
    sessionStorage.removeItem('currentUser');
    sessionStorage.clear();
    this.currentUserSubject.next(null);


    this.http.get<any>(`${environment.apiUrl}/logout`).subscribe(res => {
      // console.log(res)

      this.router.navigate(['auth/login']);
    });
    

  }

  azure_login(payload) {

    sessionStorage.setItem('access_token', JSON.stringify(payload.accessToken));

    var token = payload["idToken"]["rawIdToken"];
    return this.http
      .post<any>(`${environment.apiUrl}/loginJWT`, { token: token })
      .pipe(
        map(x => {
          if (x.user) {
            
            this.loginService.setUserLoggedIn(true);
            sessionStorage.setItem('currentUser', JSON.stringify(x.user));
            sessionStorage.setItem('actualEmployee',JSON.stringify(x.user));
            this.currentUserSubject.next(x.user);

            this.RoleIDs = x.roles.map(x=>x.RoleID);

            sessionStorage.setItem('roleID', JSON.stringify(this.RoleIDs));
            // console.log("Azure:"+JSON.stringify(this.RoleIDs))

          }
          return x.user;
        })
      );
  }

  getAzureProfile() {
    return this.http.get<any>('https://graph.microsoft.com/v1.0/me').subscribe(
      res => {
        //console.log(res);
        // console.log("azure profile")
      },
      error => {
        //console.log(error.message);
      }
    );
  }
}
