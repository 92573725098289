import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { LoginService } from './shared/services/login.service';

import { Component, ViewChild, TemplateRef, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './shared/model';
import { AuthenticationService } from './shared/services';
import { MatDialog } from '@angular/material/dialog';
import { SessionOverComponent } from './shared/components/session-over/session-over.component';

import { PrimeNGConfig } from 'primeng/api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public href: string = "";

  title = 'grandegaDev-v2';
  currentUser: User;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;

  public modalRef: BsModalRef;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,

    //auto Logout
    private idle: Idle, 
    private keepalive: Keepalive,
    private modalService: BsModalService,
    private loginService: LoginService,

    private config: PrimeNGConfig
    )
  
  {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(8000);
    // sets a timeout period of 10 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(15);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'Nicht mehr abwesend.'
      this.reset();
    });
    
    idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      //console.log('du bist raus');
      this.loginService.setUserLoggedIn(false);
      this.authenticationService.logout();
      this.router.navigate(['/auth/login']);
    });
    
    idle.onIdleStart.subscribe(() => {
      
        this.idleState = 'Du bist abwesend'
        this.childModal.show();
    });
    
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Du wirst in ' + countdown + ' Sekunden ausgeloggt.'
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.loginService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
      } else {
        idle.stop();
        console.log("Idle Stop")
      }
    })

    //this.reset();
      
  }

  ngOnInit() {
    this.config.setTranslation({
        accept: 'Ja',
        reject: 'Nein',
        dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag" ],
        dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
        dayNamesMin: ["So","Mo","Di","Mi","Do","Fr","Sa"],
        monthNames: ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
        monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun","Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        today: "Heute",
        weekHeader: "KW",
        clear: "löschen",

        startsWith: "startet mit",
        contains: "enthält",
        notContains: "enthält nicht",
        endsWith: "endet mit",
        equals: "gleich",
        notEquals: "nicht gleich",
        noFilter: "kein Filter",
        lt: "weniger als",
        lte: "< oder =",
        gt: "größer als",
        gte: "> oder =",
        is: "ist",
        isNot: "Ist nicht",
        before: "vor",
        after: "nach",
        apply: "OK",
        matchAll: "Match All",
        matchAny: "Match Any",
        addRule: "Add Rule",
        removeRule: "Remove Rule",
        choose: "Auswählen",
        upload: "Upload",
        cancel: "Abbruch",
        weak: 'schwach',
        medium: 'medium',
        strong: 'stark',
        passwordPrompt: 'Passwort eingeben',

    });
}

  reset() {
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.timedOut = false;
  }

  public setIdleManually(){
    this.idle.setIdle(1);
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    this.childModal.hide();
    this.loginService.setUserLoggedIn(false); 
    this.authenticationService.logout();
    this.router.navigate(['/auth/login']);
    //window.location.reload();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SessionOverComponent, {
        disableClose: true
     
    });

    dialogRef.afterClosed().subscribe(result => {
     
    });
  }
}
