import { Routes } from '@angular/router';
import { AuthGuard } from '../guard';

export const content: Routes = [
  {
    path: 'dashboard',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: "Dashboard"
    }
  },
  {
    path: 'school',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/school/school.module').then(m => m.SchoolModule),
    data: {
      breadcrumb: "grandega School"
    }
  },
  {
    path: 'skillmanagement',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/skillmanagement/skillmanagement.module').then(m => m.SkillmanagementModule),
    data: {
      breadcrumb: "Skillmanagement"
    }
  },
  {
    path: 'store',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/store/store.module').then(m => m.StoreModule),
    data: {
      breadcrumb: "grandega Store"
    }
  },
  {
    path: 'admin',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/admin/admin.module').then(m => m.AdminModule),
    data: {
      breadcrumb: "Administration"
    }
  },
  {
    path: 'teamleitung',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/teamleitung/teamleitung.module').then(m => m.TeamleitungModule),
    data: {
      breadcrumb: "Teamleitung"
    }
  },
  {
    path: 'mentorplus',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/mentorplus/mentorplus.module').then(m => m.MentorPlusModule),
    data: {
      breadcrumb: "Mentor+"
    }
  },
  {
    path: 'geschaeftsbereich',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/businessdivision/businessdivision.module').then(m => m.BusinessDivisonModule),
    data: {
      breadcrumb: "Geschäftsbereich"
    }
  },
  {
    path: 'sample-page',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/sample-page/sample-page.module').then(m => m.SamplePageModule),
    data: {
      breadcrumb: "Sample-Page"
    }
  },
  {
    path: 'my-payrolls',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/my-payrolls/mypayrolls.module').then(m => m.MyPayrollsModule),
    data: {
      breadcrumb: "Abrechnungen"
    }
  },
  {
    path: 'cps',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/cps/cps.module').then(m => m.CpsModule),
    data: {
      breadcrumb: "Bericht"
    }
  },
  {
    path: 'covid',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/covid/covid.module').then(m => m.CovidModule),
    data: {
      breadcrumb: "Covid"
    }
  },
  {
    path: 'crm',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/crm/crm.module').then(m => m.CrmModule),
    data: {
      breadcrumb: "XRM"
    }
  },
  {
    path: 'my-client',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/my-client/my-client.module').then(m => m.MyClientModule),
    data: {
      breadcrumb: "MyClient"
    }
  },
  {
    path: 'leave',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/leave/leave.module').then(m => m.LeaveModule),
    data: {
      breadcrumb: "Abwesenheiten"
    }
  },
  {
    path: 'expenses',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/expenses/expenses.module').then(m => m.ExpensesModule),
    data: {
      breadcrumb: "Meine Reisen"
    }
  },
  {
    path: 'okr',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/okr/okr.module').then(m => m.OkrModule),
    data: {
      breadcrumb: "OKR"
    }
  },
  {
    path: 'candidates',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/candidates/candidates.module').then(m => m.CandidatesModule),
    data: {
      breadcrumb: "Home"
    }
  },
  {
    path: 'whistleblower',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/whistleblower/whistleblower.module').then(m => m.WhistleblowerModule),
    data: {
      breadcrumb: "Whistleblower"
    }
  },
  {
    path: 'values',
    canActivate:[AuthGuard],
    loadChildren: () => import('../../components/grandega-values/grandega-values.module').then(m => m.GrandegaValuesModule),
    data: {
      breadcrumb: ""
    }
  },
]; 