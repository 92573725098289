import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { PSPElemente } from '../model';

@Injectable({ providedIn: 'root' })
export class ProjectService {
    constructor(private http: HttpClient) { }
 
    // get all projects
    getAll() {
        return this.http.get<PSPElemente[]>(`${environment.apiUrl}/pspelements`);
    }
    
    //TODO ändern des api routennamens von projects auf pspelements in Laravel
    getMy(uId: number) {
        return this.http.get<PSPElemente[]>(`${environment.apiUrl}/projects/` + uId);
    }

    getMyActive(uId: number) {
      return this.http.get<PSPElemente[]>(`${environment.apiUrl}/myactivePSPElements/` + uId);
  }

    // assign a new project to a user
    //TODO ändern des api routennamens von projects auf pspelements in Laravel
    //Alt, wird nicht mehr benötigt

    // assignPSP2User(uId, pspId) {
    //   let body = [];
    //   return this.http.put<any>(`${environment.apiUrl}/pspelement/` + uId + "/" + pspId, body);
    // }

    //Alt, wird in der Form nicht mehr benötigt
    //Neue Verwendung in CRM, nur dort dürfen PSP-Elemente gelöscht werden

    deletePSP2User(uId, pspId) {
      return this.http.delete<any>(`${environment.apiUrl}/projects/` + uId + "/" +  pspId);
    }

    changePSPStatus(PSPElementeID:number, active:string){
      let body = [];
      return this.http.put<any>(`${environment.apiUrl}/status_pspelemente/`+ PSPElementeID + "/"+ active, body);
    }

 // get all CPS
    getMyCPS(uId: number) {
      return this.http.get<PSPElemente[]>(`${environment.apiUrl}/CPS/` + uId);
  }

}
