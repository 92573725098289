import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';

import { filter } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators';
import moment from 'moment';
import { MonthlyFinalService } from '../../services/monthlyfinal.service';
import { AuthenticationService } from '../../services';
import { Monthlyfinal } from '../../model/monthlyfinal';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { PSPElemente, User } from '../../model';
import { CrmService } from '../../services/crm.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public breadcrumbs;
  public title: string

  public actualMonth;
  public actualMonthly: Monthlyfinal;
  public lastMonthly: Monthlyfinal;
  currentUser: User;
  endOfMonth = moment().clone().endOf('month').date(); //Letzer tag des monats 
  showPopUp = false;
  RoleIDs;
  // AdminTemp = false;
  actualYear;
  previousMonthly;
  pspElemente;
  pspElement

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private monthlyFinalService: MonthlyFinalService,
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private http: HttpClient,
    private crmService: CrmService) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(route => {
        let snapshot = this.router.routerState.snapshot;
        let title = route.snapshot.data['title'];
        let parent = route.parent.snapshot.data['breadcrumb'];
        let child = route.snapshot.data['breadcrumb'];
        this.breadcrumbs = {};
        this.title = title;
        this.breadcrumbs = {
          "parentBreadcrumb": parent,
          "childBreadcrumb": child
        }
      });

   

   
  }


  ngOnInit() { 
     //-----MonthlyFinal-----
     this.lastMonthly = new Monthlyfinal();
     this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));
    //  if (this.RoleIDs.some(el => (el === "107"))) {
    //    this.AdminTemp = true;
    //  }
 
     this.currentUser = this.authenticationService.currentUserValue

     if (JSON.parse(sessionStorage.getItem('lastMonthly')) == null) {
     if(moment().month() == 0){
      this.monthlyFinalService.getMonthlyFinal(this.currentUser.PersonID, 12, moment().subtract(1, 'M').year()).subscribe(monthly => {
        this.lastMonthly = monthly;
        sessionStorage.setItem('lastMonthly', JSON.stringify(this.lastMonthly));
      });
      } else{
       this.monthlyFinalService.getMonthlyFinal(this.currentUser.PersonID, moment().month(), moment().subtract(1, 'M').year()).subscribe(monthly => {
        this.lastMonthly = monthly;
        sessionStorage.setItem('lastMonthly', JSON.stringify(this.lastMonthly));
      });
      }
      
     } else {
       this.lastMonthly = JSON.parse(sessionStorage.getItem('lastMonthly'));
     }
     if (this.lastMonthly != null && this.lastMonthly.Completed == "false") {
       this.showPopUp = true;
       if(moment().month() == 0){
       this.actualMonth = 12;
       } else{
        this.actualMonth = moment().month();
       }
       this.actualYear = moment().subtract(1, 'M').year();

     } else {
       if(moment().month() == 11){
        this.actualMonth = 12;
       }else{
        this.actualMonth = moment().add(1, 'M').month();
       }
       this.actualYear = moment().year();
 
       if (JSON.parse(sessionStorage.getItem('actualMonthly')) == null) {

         this.monthlyFinalService.getMonthlyFinal(this.currentUser.PersonID, this.actualMonth, moment().year()).subscribe(actualMonthly => {
           this.actualMonthly = actualMonthly;
 
           if (this.actualMonthly != null && this.endOfMonth - 5 <= moment().date()
            ) {
             this.showPopUp = (this.actualMonthly.Completed == "false");
             sessionStorage.setItem('actualMonthly', JSON.stringify(this.actualMonthly));
           }
           if (this.actualMonthly == null && this.endOfMonth - 5 <= moment().date()
            ) {
             this.showPopUp = true;
 
           }
         });
       } else {
         this.actualMonthly = JSON.parse(sessionStorage.getItem('actualMonthly'));
         //Tage ändern
         this.showPopUp = (this.actualMonthly.Completed == "false" && this.endOfMonth - 5 <= moment().date()
         );
       }
     }
     
     //-----Project-Onboarding-----

     this.pspElemente = [];
     let tempElemente = [];
     let tempBool = true;
     
     this.crmService.getPSPElementByPersonID(this.currentUser.PersonID).subscribe(pspElemente => {
       // this.pspElement = pspElemente;
       tempElemente = pspElemente;
       for (let i = 0; i < Object.keys(pspElemente).length; i++) {
         if (pspElemente[i].OnboardingStartDate == null) {
 
           tempElemente.map(element => {
             if (pspElemente[i].ProjectID == element.ProjectID) {
               tempBool = false;
             }
           });
 
           if (tempBool) {
             this.pspElemente.push(pspElemente[i]);
           }
          tempBool = true;
         }
       }
     });
  }

  ngOnDestroy() { }

  routing() {
    this.router.navigate(['/dashboard/monatsabschluss/' + this.actualMonth + '/' + this.actualYear]);
  }

}
