// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "YOUR_APIKEY",
    authDomain: "YOUR_AUTH_DOMAIN",
    databaseURL: "YOUR_DATABASE_URL",
    projectId: "YOUR_PROJECT_ID",
    storageBucket: "universal-angular-85d7e.appspot.com",
    messagingSenderId: "YOUR_MESSAGE_SENDER_ID",
    appId: "YOUR_APPID"
  },
  production: false,
  apiUrl: "https://dev.grandega-access.de/api",
  url: "https://dev.grandega-access.de",
  client_id:"b682311f-5e4b-4c44-bfbd-82f197c3728c",
  version:"Developer Version"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.