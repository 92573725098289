import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, AlertService } from '../services';
import { User } from '../model';
import { Subscription } from 'rxjs';
import { EmployeeService } from '../services/employee.service';
import { NavService } from '../services/nav.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  currentUser: User;
  currentroles: any;
  RoleIDs: any;
  currentUserSubscription: Subscription;
  currentRolesSubscription: Subscription;

  constructor(private authenticationService: AuthenticationService, public router: Router, private alertService: AlertService,
    private employeeService: EmployeeService,
    public navServices: NavService

  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));

    if (this.currentUser == null) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      })
    }
    else{

      if (this.RoleIDs == null) {
        this.currentRolesSubscription = this.employeeService.getRoles(this.currentUser.PersonID).subscribe(roles => {
          this.currentroles = roles;
          this.RoleIDs = this.currentroles.map(x => x.RoleID);
          if (this.RoleIDs != null) {
            sessionStorage.setItem('roleID', JSON.stringify(this.RoleIDs));
          }

        });
      } else {
        this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));
      };

      if (this.currentUser) {
        if (this.currentUser.PersonType === 'extern' && this.currentUser.Password_Changed === 'false') {
          if (this.router.url != '/mydata') {
            // password not changed so redirect to settings page
            // this.router.navigate(['/mydata']);
            this.alertService.error('Bitte ändern Sie Ihr Passwort.');
          }
        }
      }

      if (this.currentUser || this.authenticationService.azureLoginSuccess) {
        // authorised so return true

        switch (state.url) {


          //---------------------------Admin Users--------------------------------------------------

          //Teamleiter 104
          case '/teamleitung/member-list':
            if (this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            }
            break;

          case '/teamleitung/member-detail':
            if (this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            }
            break;
            
          case '/teamleitung/member-edit':
            if (this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            }
            break; 

            case '/teamleitung/trial-period':
            if (this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            }
            break; 

            case '/teamleitung/project-statisfaction':
            if (this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            }
            break;

          //TechAdmin 107
          case 'NOCH EINZUFÜGEN':
            if (this.RoleIDs.some(el => (el === "107") || (el === "107"))) {
              return true;
            }
            break;

          //AdminSchool 119
          case '/admin/school-mgmt':
            if (this.RoleIDs.some(el => (el === "119") || (el === "107"))) {
              return true;
            }
            break;

          case '/admin/school-mgmt/dashboard':
            if (this.RoleIDs.some(el => (el === "119") || (el === "107"))) {
              return true;
            }
            break;

          case '/admin/school-mgmt/training-detail':
            if (this.RoleIDs.some(el => (el === "119") || (el === "107"))) {
              return true;
            }
            break;

          case '/admin/school-mgmt/booked-training':
            if (this.RoleIDs.some(el => (el === "119") || (el === "107"))) {
              return true;
            }
            break;

          case '/admin/school-mgmt/detail-user':
            if (this.RoleIDs.some(el => (el === "119") || (el === "107"))) {
              return true;
            }
            break;

          //AdminStore 120
          case '/admin/product-mgmt':
            if (this.RoleIDs.some(el => (el === "120") || (el === "107"))) {
              return true;
            }
            break;

          case '/admin/product-mgmt/product-detail':
            if (this.RoleIDs.some(el => (el === "120") || (el === "107"))) {
              return true;
            }
            break;


          //AdminPayroll 121
          case '/admin/new-payrolls':
            if (this.RoleIDs.some(el => (el === "121") || (el === "107"))) {
              return true;
            }
            break;


          //---------------------------Normal Users--------------------------------------------------

          //CRMUser 108
          case '/crm':
            if (this.RoleIDs.some(el => (el === "108") || (el === "107"))) {
              return true;
            }
            break;

          //PayrollUSer 111
          case '/my-payrolls':
            if (this.RoleIDs.some(el => (el === "111") || (el === "107"))) {
              return true;
            }
            break;
          //---------------------------Components--------------------------------------------------

          //Dashboard 103
          case '/dashboard/default':

            return true;


          case '/dashboard/releasenotes':

            return true;

          //   if (this.RoleIDs.some(el => (el === "103") || (el === "105"))) {
          //     return true;
          //   }
          // break;

          case '/dashboard/my-profile':
            if (this.RoleIDs.some(el => (el === "103") || (el === "107"))) {
              return true;
            }
            break;


          case '':
            if (this.RoleIDs.some(el => (el === "103") || (el === "107"))) {
              return true;
            }
            break;

            case '/whistleblower/mgmt-new':
            if (this.RoleIDs.some(el => (el === "142") || (el === "107"))) {
              return true;
            }
            break;

          // case '/':
          //   return true;

          case '/bewerber':
            return true;


          //CPS 112
          // case '/cps':
          //   if (this.RoleIDs.some(el => (el === "112") || (el === "107"))) {
          //     return true;
          //   }
          //   break;

          // School 113
          case '/school/school-list':
            if (this.RoleIDs.some(el => (el === "113") || (el === "107"))) {
              return true;
            }
            break;

          case '/school/school-booking':
            if (this.RoleIDs.some(el => (el === "113") || (el === "107"))) {
              return true;
            }
            break;

            case '/skillmanagement/user-detail':
            if (this.RoleIDs.some(el => (el === "150") || (el === "151") || (el === "107"))) {
              return true;
            }
            break;

            case '/skillmanagement/reports/skill-list':
            if (this.RoleIDs.some(el => (el === "150") || (el === "151") || (el === "107"))) {
              return true;
            }
            break;
           

          //Reisekosten 114
          case 'TBD':
            if (this.RoleIDs.some(el => (el === "114") || (el === "107"))) {
              return true;
            }
            break;

          //Abwesenheiten 115
          case '/leave/leave-list':
            if (this.RoleIDs.some(el => (el === "115") || (el === "107"))) {
              return true;
            }
            break;

          case '/leave/new-leave':
            if (this.RoleIDs.some(el => (el === "115") || (el === "107"))) {
              return true;
            }
            break;

          case '/leave/edit-leave':
            if (this.RoleIDs.some(el => (el === "115") || (el === "107"))) {
              return true;
            }
            break;

          //Store 116
          case '/store/start':
            if (this.RoleIDs.some(el => (el === "116") || (el === "107"))) {
              return true;
            }
            break;

          case '/store/add-cart':
            if (this.RoleIDs.some(el => (el === "200") || (el === "201"))) {
              return true;
            }
            break;

          case '/store/check-out':
            if (this.RoleIDs.some(el => (el === "116") || (el === "107"))) {
              return true;
            }
            break;

          case '/store/invoice':
            if (this.RoleIDs.some(el => (el === "116") || (el === "107"))) {
              return true;
            }
            break;

          //InfoDesk 117
          case 'TBD':
            if (this.RoleIDs.some(el => (el === "117") || (el === "107"))) {
              return true;
            }
            break;

          //Performance Records 110
          case '/performance-records/new-pr':
            if (this.RoleIDs.some(el => (el === "110") || (el === "107"))) {
              return true;
            }
            break;

          case '/performance-records/my-pr':
            if (this.RoleIDs.some(el => (el === "110") || (el === "107"))) {
              return true;
            }
            break;

          case '/performance-records/edit-pr':
            if (this.RoleIDs.some(el => (el === "110") || (el === "107"))) {
              return true;
            }
            break;

          case '/crm/reports/project-satisfaction':
              if (this.RoleIDs.some(el => (el === "108") || (el === "107") || (el === "104"))) {
                return true;
              }
              break;

          case '/crm/businesspartner/chances-list':
                if (this.RoleIDs.some(el => (el === "108") || (el === "107") || (el === "104"))) {
                  return true;
                }
                break;

          case '/crm/reports/delivery-master-list':
                if (this.RoleIDs.some(el => (el === "108") || (el === "107") || (el === "104") || (el === "135"))) {
                  return true;
                }
                break;

          //  case '/okr/rating/member':
          //   if (this.RoleIDs.some(el => (el === "131") || (el === "107"))) {
          //     return true;
          //   }
          //   break;

          // case '/okr/rating/objectiveowner/:month/:year':
          //   if (this.RoleIDs.some(el => (el === "132") || (el === "107"))) {
          //     return true;
          //   }
          //   break;

          //---------------------------Full Routes--------------------------------------------------

          case '/error/404':
            return true;

          case '/comingsoon':
            return true;

          case '/maintenance':
            return true;

          case '/forbidden':
            return true;

          case '/auth/login':
            return true;

          default:
            var regExp = new RegExp('/school/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "113") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/dashboard/monatsabschluss/.*')
            var bool= regExp.test(state.url);
            //TODO (el === "104") || einfügen
            if(bool && this.RoleIDs.some(el => (el === "103"))){
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/landing-page/.*')
            var bool= regExp.test(state.url);
            //TODO (el === "104") || einfügen
            if(bool && this.RoleIDs.some(el => (el === "103"))){
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/covid/.*')
            var bool= regExp.test(state.url);
            //TODO (el === "104") || einfügen
            if(bool && this.RoleIDs.some(el => (el === "103"))){
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/dashboard/project-onboarding/.*')
            var bool= regExp.test(state.url);
            //TODO (el === "104") || einfügen
            if(bool && this.RoleIDs.some(el => (el === "103"))){
              return true;
            } else if(bool){
              break;
            }

            //TODO CPS Admin Rolle anlegen
            var regExp = new RegExp('/cps/admin.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/cps/m.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "112") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            //TODO CPS Consultant Rolle anlegen
            var regExp = new RegExp('/cps/c.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "112") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/my-client/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "123") || (el === "107") || (el === "147"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/leave/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "115") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/expenses/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "114") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            regExp = new RegExp('/crm.*');
            bool = regExp.test(state.url);

            if (bool && this.RoleIDs.some(el => (el === "108") || (el === "107"))) {
              return true
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/mentorplus.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "133") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/leave-mgmt.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/member-detail.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/member-list.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/availabilities.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/free-days.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/delivery-master.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/emergency-contact-project.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/emergency-contact-private.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/covid-tests.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }
            var regExp = new RegExp('/teamleitung/project-satisfaction.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }
            var regExp = new RegExp('/teamleitung/okr-ratings.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/ql.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/jzg/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/teamleitung/p-cockpit/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "104") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            // var regExp = new RegExp('/teamleitung/whistleblower/.*')
            // var bool = regExp.test(state.url);
            // if (bool && this.RoleIDs.some(el => (el === "122") || (el === "107"))) {
            //   return true;
            // }

            var regExp = new RegExp('/geschaeftsbereich/mp/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "122") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/geschaeftsbereich/spotlight-manager/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "149") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/geschaeftsbereich/finance/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "135") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/geschaeftsbereich/sales/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "141") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/geschaeftsbereich/contracts/dashboard.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "136") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/geschaeftsbereich/contracts/psp-element/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "139") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/geschaeftsbereich/contracts/consulting/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "140") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/geschaeftsbereich/people-culture/candidates/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "138") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/geschaeftsbereich/people-culture/hrm/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "142") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/geschaeftsbereich/technology/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "148") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/okr/work/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "125") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/okr/rating/objectiveowner/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "132") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/okr/rating/member/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "131") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/okr/rating/oowner/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "131") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/okr/rating/self/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "132") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/admin/okr/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "126") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            //Rolle in SQL erstellen und nachtragen
            var regExp = new RegExp('/admin/expense-mgmt/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "124") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            //Rolle in SQL erstellen und nachtragen
            var regExp = new RegExp('/admin/candidate-mgmt/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "107") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            //Rolle in SQL erstellen und nachtragen
            var regExp = new RegExp('/admin/it-asset-mgmt/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "107") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/candidates/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "127") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            // var regExp = new RegExp('/my-profile.*')
            // var bool = regExp.test(state.url);
            // if (bool && this.RoleIDs.some(el => (el === "103") || (el === "107"))) {
            //   return true;
            // }

            var regExp = new RegExp('/admin/usr-mgmt/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "118") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/admin/okr/rating/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "131") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/admin/school-mgmt/.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "119") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/values*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "137") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            var regExp = new RegExp('/skillmanagement/admin.*')
            var bool = regExp.test(state.url);
            if (bool && this.RoleIDs.some(el => (el === "151") || (el === "107"))) {
              return true;
            } else if(bool){
              break;
            }

            this.router.navigate(['/error/404']);
            return false;
        }
        this.router.navigate(['/forbidden']);
        return false;
      }
    }


    // not logged in so redirect to login page with the return url

    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;

  }
}
