import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Expense } from '../model/expenses'
import { Receipt } from '../model/receipts';

@Injectable({ providedIn: 'root' })
export class ExpenseService {
  constructor(private http: HttpClient) { }

  getMyExpenses() {
    return this.http.get<Expense>(`${environment.apiUrl}/myexpenses`);
  }

  getMyExpenseFiles(ExpenseID: number) {
    return this.http.get<any>(`${environment.apiUrl}/expense/${ExpenseID}`);
  }

  getAllSumExpenses() {
    return this.http.get<Expense>(`${environment.apiUrl}/sumexpenses`);
  }

  getMySumExpenses(PersonID: number) {
    return this.http.get<Expense>(`${environment.apiUrl}/sumexpenses/${PersonID}`);
  }

  saveNewExpense(PersonID, StartDate, EndDate) {
    return this.http.post<any>(`${environment.apiUrl}/newexpense`, {
      'PersonID': PersonID,
      'StartDate': StartDate,
      'EndDate': EndDate
    });
  }

  editExpense(ExpenseID, StartDate, EndDate) {
    return this.http.put<any>(`${environment.apiUrl}/editexpense/${ExpenseID}`, {
      'StartDate': StartDate,
      'EndDate': EndDate
    });
  }

  deleteExpense(ExpenseID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/deleteexpense/` + ExpenseID);
  }

  getMyReceipts(PersonID: number, ExpenseID:number) {
    return this.http.get<Receipt>(`${environment.apiUrl}/myreceipts/` + PersonID + "/" + ExpenseID);
  }

  getPSPChecks() {
    return this.http.get<any>(`${environment.apiUrl}/pspchecks`);
  }

  //Admin
  getExpenseByID(ExpenseID: number){
    return this.http.get<any>(`${environment.apiUrl}/expense/${ExpenseID}`);
  }

  getExpenses() {
    return this.http.get<Expense>(`${environment.apiUrl}/checkexpenses`);
  }

  getAllExpensesToCustomer() {
    return this.http.get<any>(`${environment.apiUrl}/allExpensesToCustomer`);
  }
}
