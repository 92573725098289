import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Upload } from '../model';

@Injectable({ providedIn: 'root' })
export class UploadService {
    constructor(private http: HttpClient) { }

    // show all uploads
    getAll() {
        return this.http.get<Upload>(`${environment.apiUrl}/data`);
    }

    update(id: number, data) {
        return this.http.put<any>(`${environment.apiUrl}/data/` + id, data);
    }

    delete(id: number) {
        return this.http.delete<any>(`${environment.apiUrl}/del-pr/` + id);
    }
    
    getAllPayrolls() {
        return this.http.get<Upload>(`${environment.apiUrl}/payrolls`);
    }

    deletePayroll(user_id: number, filename: string ) {
        return this.http.delete<any>(`${environment.apiUrl}/payrolls/` + user_id+"/"+ filename);
    }
    
    getMyPayrolls(id: number) {
        return this.http.get<Upload[]>(`${environment.apiUrl}/payrolls/` + id);
    }

    getAllPublicFiles() {
        return this.http.get<any>(`${environment.apiUrl}/public`);
    }

    getPublicFile(filename) {
        return this.http.get<any>(`${environment.apiUrl}/public/${filename}`);
    }

    getMyPRs() {
        return this.http.get<any>(`${environment.apiUrl}/my-prs`);
    }

    uploadCandidateData(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/candidatefiles`, data, {
            headers: headers
        });
    }

    uploadDocuments(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/uploadDocuments`, data, {
            headers: headers
        });
    }

    uploadMondayVideo(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/uploadMondayVideo`, data, {
            headers: headers
        });
    }

    getMondayVideo(filename){
        return this.http.get(`${environment.apiUrl}/mondayVideo/${filename}`,{responseType: 'text'});
    }

    getMondayVideoHistory(){
        return this.http.get<any>(`${environment.apiUrl}/mondayVideoHistory`);
    }

    deleteMondayVideo(AllgDocumentID: number){
        return this.http.delete<any>(`${environment.apiUrl}/mondayVideo/`+AllgDocumentID);
      }

    uploadJzgDoc(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/jzgdatafiles`, data, {
            headers: headers
        });
    }

    uploadContractDoc(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/contractdatafiles`, data, {
            headers: headers
        });
    }

    uploadCovidTest(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/covidtest`, data, {
            headers: headers
        });
    }

    uploadPerformanceRecord(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/uploadPRecord`, data, {
            headers: headers
        });
    }

    uploadApprovedExpense(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/approvedexpense`, data, {
            headers: headers
        });
    }

    // uploadApprovedExpenseEdited(data){
    //     // const headers = new HttpHeaders();
    //     // return this.http.put<any>(`${environment.apiUrl}/approvedexpenseEdited`, data, {
    //     //     headers: headers
    //     // });
    //     return this.http.put<any>(`${environment.apiUrl}/approvedexpenseEdited`, data);

    // }

    uploadAssetHandoverProtocol(data){
        const headers = new HttpHeaders();
        return this.http.post<any>(`${environment.apiUrl}/uploadhandoverprotocol`, data, {
            headers: headers
        });
    }
}
