import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../services/nav.service';
import { User, EmployeeRoles } from '../../../shared/model';
import { AuthenticationService } from '../../../shared/services';
import { Subscription } from 'rxjs';
import { HeaderComponent } from '../header/header.component';
import { EmployeeService } from '../../services/employee.service';
import { LoginService } from '../../services/login.service';
import { AppComponent } from 'src/app/app.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {
  public menuItems: Menu[];
  public head: HeaderComponent;
  public url: any;
  public fileurl: any;
  currentUser: User;
  currentUserSubscription: Subscription;
  currentRolesSubscription: Subscription;
  currentroles: any;
  RoleIDs: any;

  loggedIn;



  constructor(
    private router: Router,
    public navServices: NavService,
    private authenticationService: AuthenticationService,
    private employeeService: EmployeeService,
    private loginService: LoginService,
    private appComponent: AppComponent,
    private toastr: ToastrService,
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
    this.RoleIDs = JSON.parse(sessionStorage.getItem('roleID'));

    if (JSON.parse(sessionStorage.getItem('roleID')) == null && this.currentUser != null) {
      this.currentRolesSubscription = this.employeeService.getRoles(this.currentUser.PersonID).subscribe(roles => {
        this.currentroles = roles;
        this.RoleIDs = this.currentroles.map(x => x.RoleID);
        sessionStorage.setItem('roleID', JSON.stringify(this.RoleIDs));

        this.navServices.getItems(this.RoleIDs).subscribe(menuItems => {
          this.menuItems = menuItems;
          this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
              menuItems.filter(items => {
                if (items.path === event.url)
                  this.setNavActive(items)
                if (!items.children) return false
                items.children.filter(subItems => {
                  if (subItems.path === event.url)
                    this.setNavActive(subItems)
                  if (!subItems.children) return false
                  subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url)
                      this.setNavActive(subSubItems)
                  })
                })
              })
            }
          })
        })
      });
    }
    else {
      this.navServices.getItems(this.RoleIDs).subscribe(menuItems => {
        this.menuItems = menuItems;
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            menuItems.filter(items => {
              if (items.path === event.url)
                this.setNavActive(items)
              if (!items.children) return false
              items.children.filter(subItems => {
                if (subItems.path === event.url)
                  this.setNavActive(subItems)
                if (!subItems.children) return false
                subItems.children.filter(subSubItems => {
                  if (subSubItems.path === event.url)
                    this.setNavActive(subSubItems)
                })
              })
            })
          }
        })
      })
    }
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false


      if (menuItem.children && menuItem.children.includes(item))

        menuItem.active = true
      if (menuItem.children) {
        if (this.navServices.isMobile()) {
          this.navServices.collapseSidebar = !this.navServices.collapseSidebar
        }
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true

          }
        })
      }
    })
    // this.navServices.collapseSidebar=!this.navServices.collapseSidebar;
  }

  checkCollapse(menuitem) {

    this.checkIfLoggedIn();

    if (this.navServices.isMobile()) {
      this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    }
    else {
      switch (menuitem.title) {
        case 'XRM':
          this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
          break;

        case 'grandega OKR':
          this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
          break;

        case 'Teamleitung':
          this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
          break;

        case 'Geschäftsbereich':
          this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
          break;

        case 'grandega School':
          this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
          break;

        case 'Skillmanagement':
          this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
          break;
      }
    }
  }

  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  checkIfLoggedIn() {

    this.navServices.checkIfLoggedIn().subscribe(result => {
      this.loggedIn = result.error;

      if (this.loggedIn == 'false') {
        this.toastr.info('Du warst zu lange abwesend, bitte erneut anmelden.', 'Information');
        this.wait(2000);
        this.appComponent.logout();

      }

    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      if (this.menuItems != null) {
        this.menuItems.forEach(a => {
          if (this.menuItems.includes(item))
            a.active = false

          if (!a.children) return false
          a.children.forEach(b => {
            if (a.children.includes(item)) {
              b.active = false
            }
          })
        });
      }
    }
    item.active = !item.active

  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

}
