import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SchoolTraining, BookedSchoolTraining, SchoolCategorys } from '../model';


@Injectable({ providedIn: 'root' })
export class SchoolService {
  constructor(private http: HttpClient) { }

getTrainings() {
  return this.http.get<any>(`${environment.apiUrl}/trainings`);
}

getBookedTrainings(id: number) {
  return this.http.get<BookedSchoolTraining[]>(`${environment.apiUrl}/bookedtrainings/${id}`);
}

getAllBookedTrainings() {
  return this.http.get<any>(`${environment.apiUrl}/allbookedtrainings`);
}

getTPBudget(PersonID: number) {
  return this.http.get<number>(`${environment.apiUrl}/tpbudget/${PersonID}`);
}

getMaxTP(PersonID: number, Year: number) {
  return this.http.get<any>(`${environment.apiUrl}/maxTraininpoints/${PersonID}/`+Year);
}

getTraining(id: number) {
  return this.http.get<SchoolTraining>(`${environment.apiUrl}/trainings/${id}`);
}

getAllTrainingDates() {
  return this.http.get<any>(`${environment.apiUrl}/schoolDates`);
}

getRequestedTrainings(uId: number){
  return this.http.get<BookedSchoolTraining>(`${environment.apiUrl}/requestedTrainings/${uId}`);
}

approveTraining(uId: number, TrainingDateID:number, approve:boolean){
  let body = [];
  return this.http.put<any>(`${environment.apiUrl}/approveTraining/`  + uId + "/" + TrainingDateID + "/"+ approve, body);
}

bookTraining(PersonID: number, TrainingDateID: number, allowed: boolean) {
  let body = [];
  return this.http.put<any>(`${environment.apiUrl}/school/` + PersonID + "/" + TrainingDateID + "/" + allowed, body);
}

deleteTraining(PersonID: number, TrainingDateID: number) {

  return this.http.delete<any>(`${environment.apiUrl}/school/`+ PersonID + "/" + TrainingDateID);

} 

delete(training: SchoolTraining){
  //sessionStorage.removeItem('actualTraining');
  return this.http.delete<any>(`${environment.apiUrl}/training/${training.TrainingID}`);
}

getSchoolCategorys(){
  return this.http.get<SchoolCategorys>(`${environment.apiUrl}/schoolCategorys`);
}

getSchoolDates(TrainingID){
  return this.http.get<any>(`${environment.apiUrl}/schoolDates/`+TrainingID);
}

getAllParticipants(TrainingID: number){
  return this.http.get<any>(`${environment.apiUrl}/trainingparticipants/${TrainingID}`);
}


getDetailTrainingData(trainingID){
  return this.http.get<any>(`${environment.apiUrl}/detailTrainingData/`+trainingID);
}

getTrainingDateByID(trainingDateID){
  return this.http.get<any>(`${environment.apiUrl}/schoolDateByID/`+trainingDateID);
}


deleteTrainingDate(TrainingDateID){
  return this.http.delete<any>(`${environment.apiUrl}/trainingDate/${TrainingDateID}`);
}
}
