
export class Monthlyfinal {
  MonthlyfinalID: number;
  PerformanceRecordStep: string;
  NoProject: string;
  LeaveVacationStep: string;
  LeaveSickStep: string;
  ConsultantProfileStep: string;
  SchoolStep: string;
  SkillMgmtStep: string;
  ExpenseStep: string;
  ProlongationStep: string;
  RestrictionStep: string;
  OKRStep: string;
  Completed: string;
  Month: string;
  Year: string;
  created_at: string;
  updated_at: string;
  created_by: number;
  updated_by: number;
}
