import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User } from '../model';
import { Employee } from '../model/employee';
import { EmployeeProfile } from '../model/employeeprofile';
import { EmployeeRoles } from '../model/employeeroles';

@Injectable({ providedIn: 'root' })
export class EmployeeService {
    role: string;
    
    constructor(private http: HttpClient) { }

    // only for admin
    getAll() {
        return this.http.get<Employee[]>(`${environment.apiUrl}/employees`);
    }

    getAllIntern() {
        return this.http.get<Employee[]>(`${environment.apiUrl}/employeesIntern`);
    }

    getAllBirthdays() {
        return this.http.get<any>(`${environment.apiUrl}/birthdays`);
    }

    getAllTeamleader() {
        return this.http.get<any>(`${environment.apiUrl}/teamleader`);
    }

    getMyTeam(id: number){
        return this.http.get<Employee[]>(`${environment.apiUrl}/myteam/${id}`);
    }

    getMyTeamAndMyself(id: number){
        return this.http.get<Employee[]>(`${environment.apiUrl}/myteamandmyself/${id}`);
    }

    getMyMentees(){
        return this.http.get<Employee[]>(`${environment.apiUrl}/mymentees/`);
    }

    getAvailabilities(){
        return this.http.get<any>(`${environment.apiUrl}/availabilities`);
    }

    getProjectEmergencyContacts(){
        return this.http.get<any>(`${environment.apiUrl}/allEmergencyContacts`);
    }

    getPrivateEmergencyContacts(){
        return this.http.get<any>(`${environment.apiUrl}/allPrivateEmergencyContacts`);
    }

    getMyTeamBudget(id: number){
        return this.http.get<any>(`${environment.apiUrl}/myteambudget/${id}`);
    }

    getMyTeamBudgetv2(PersonID: number){
        return this.http.get<Employee[]>(`${environment.apiUrl}/myteambudgetv2/${PersonID}`);
    }

    getMyTeamLeaves(PersonID: number){
        return this.http.get<Employee[]>(`${environment.apiUrl}/myteamleavedays/${PersonID}`);
    }

    getMyTeamMemberProDedications(PersonID: number){
        return this.http.get<any>(`${environment.apiUrl}/pro-dedications/${PersonID}`);
    }

    // only for admin
    getById(id: number) {
        return this.http.get<Employee>(`${environment.apiUrl}/employees/${id}`);
    }

    getRole(id: number) {
        return this.http.get<EmployeeProfile>(`${environment.apiUrl}/employeerole/${id}`);
    }

    getRoles(id: number) {
        return this.http.get<EmployeeRoles>(`${environment.apiUrl}/employeeroles/${id}`);
    }

    //MaCareNew
    getMaCareList(actualCalendarWeek) {
        return this.http.get<any>(`${environment.apiUrl}/maCare/`+actualCalendarWeek);
    }

    getMaCareListByID(PersonID) {
        return this.http.get<any>(`${environment.apiUrl}/maCareByID/`+PersonID);
    }

    // only for admin
    // register(user: User) {
    //   console.log("registered the following user:");
    //   console.log(user);
    //     return this.http.post(`${environment.apiUrl}/users/register`, user);
    // }
    
    updateUser(employee: Employee) {
        return this.http.put<any>(`${environment.apiUrl}/editemployee/${employee.PersonID}`,  employee);
    }

    getProfileData(PersonID: number) {
        return this.http.get<any>(`${environment.apiUrl}/profileData/`+PersonID);
    }

    getProfileUsers(ProfileID: number) {
        return this.http.get<any>(`${environment.apiUrl}/profile/users/`+ProfileID);
    }

    getAssignedAssets(PersonID: number) {
        return this.http.get<any>(`${environment.apiUrl}/assigendAssets/`+PersonID);
    }

    getAllJzg() {
        return this.http.get<any>(`${environment.apiUrl}/jzgs`);
    }

    getJzgByID(id) {
        return this.http.get<any>(`${environment.apiUrl}/jzgByID/`+id);
    }

    deleteJzg(JzgID: number) {
        return this.http.delete<any>(`${environment.apiUrl}/jzg/`+JzgID);
      }

    getVentures() {
        return this.http.get<any>(`${environment.apiUrl}/firmen`);
    }

    getWhistleblows() {
        return this.http.get<any>(`${environment.apiUrl}/whistleblows`);
    }

    getWhistleblowByID(id) {
        return this.http.get<any>(`${environment.apiUrl}/whistleblow/`+id);
    }

    deleteProDedication(ProDedicationID: number) {
        return this.http.delete<any>(`${environment.apiUrl}/prodedication/delete/`+ProDedicationID);
    }

    getHRMData(PersonID: number) {
        return this.http.get<any>(`${environment.apiUrl}/hrm/details/`+PersonID);
    }

    getPrivateMail(PersonID: number) {
        return this.http.get<any>(`${environment.apiUrl}/hrm/privateMail/`+PersonID);
    }

    getMyTeamHRMData() {
        return this.http.get<any>(`${environment.apiUrl}/hrm/teamleader/details`);
    }

    getMyTeamTrialPeriods() {
        return this.http.get<any>(`${environment.apiUrl}/hrm/teamleader/trial-period`);
    }

    deleteBudget(BalanceID: number){
        return this.http.delete<any>(`${environment.apiUrl}/vacation/delete/`+BalanceID);
    }


    //Skillmanagement
    getMySkillmanagementData() {
        return this.http.get<any>(`${environment.apiUrl}/mySkillmanagementData`);
    }

    //Skillmanagement
    getAdminSkillmanagementData() {
        return this.http.get<any>(`${environment.apiUrl}/adminSkillmanagementData`);
    }

    getAllSkills() {
        return this.http.get<any>(`${environment.apiUrl}/skills`);
    }

    getAllSkillsAndCategories() {
        return this.http.get<any>(`${environment.apiUrl}/skillsAndCategories`);
    }

    getAllSkillsAndCategoriesAdmin() {
        return this.http.get<any>(`${environment.apiUrl}/skillsAndCategoriesAdmin`);
    }


    getSkillmanagementData(PersonID) {
        return this.http.get<any>(`${environment.apiUrl}/skillmanagementData/`+PersonID);
    }

    getSkillsFromEmployee(PersonID) {
        return this.http.get<any>(`${environment.apiUrl}/skills/employee/`+PersonID);
    }

    getBpSkillsFromEmployee(PersonID) {
        return this.http.get<any>(`${environment.apiUrl}/bpSkills/employee/`+PersonID);
    }

    getSkillMainCategories() {
        return this.http.get<any>(`${environment.apiUrl}/skillMainCategories`);
    }
    getSkillSubCategories() {
        return this.http.get<any>(`${environment.apiUrl}/skillSubCategories`);
    }
    getSkillSubCategoryItems() {
        return this.http.get<any>(`${environment.apiUrl}/skillSubCategoryItems`);
    }

    deleteSkillFromEmployee(SkillToEmployeeID: number){
        return this.http.delete<any>(`${environment.apiUrl}/skill/employee/`+SkillToEmployeeID);
    }

    deleteBpSkillFromEmployee(SkillBPToEmployeeID: number){
        return this.http.delete<any>(`${environment.apiUrl}/bpSkill/employee/`+SkillBPToEmployeeID);
    }

    deleteSkillItem(SkillSubCategoryItemID: number){
        return this.http.delete<any>(`${environment.apiUrl}/skill/subCategoryItem/`+SkillSubCategoryItemID);
    }

    deleteSkillSubCategory(SkillSubCategoryID: number){
        return this.http.delete<any>(`${environment.apiUrl}/skill/subCategory/`+SkillSubCategoryID);
    }


    //Offboarding
    getOffboardingList() {
        return this.http.get<any>(`${environment.apiUrl}/offboardingList`);
    }

    getOffboardingDetailData(PersonID) {
        return this.http.get<any>(`${environment.apiUrl}/offboardingDetailData/`+PersonID);
    }

    
}
