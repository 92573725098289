import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User } from '../model';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    // only for admin
    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    // only for admin
    getById(PersonID: number) {
        return this.http.get(`${environment.apiUrl}/users/${PersonID}`);
    }

    // only for admin
    register(user: User) {
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }

    update(user: User) {
        return this.http.put<any>(`${environment.apiUrl}/users/${user.PersonID}`, user);
    }
    updateBankInfo(user: User) {
        return this.http.put<any>(`${environment.apiUrl}/users/bankInfo/${user.PersonID}`, user);
    }

    delete(user: User) {
        return this.http.delete<any>(`${environment.apiUrl}/users/${user.PersonID}`);
    }

    changePW(user: User, newPw: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/changePassword/` + user.PersonID, {
            'password': newPw
        });
    }

    //Admin User Berechtigungen

    getRoles(){
        return this.http.get<any>(`${environment.apiUrl}/roles`);
    }


    getProfiles(){
        return this.http.get<any>(`${environment.apiUrl}/profiles`);
    }

    getProfileDetailData(ProfileID: number){
        return this.http.get<any>(`${environment.apiUrl}/profileDetailData/` + ProfileID);
    }

    getProfilestoEmployees(){

    }

    removeTeamleaderFromEmployee(PersonID){
        return this.http.put<any>(`${environment.apiUrl}/remove/teamleader/${PersonID}`,{});
    }

    deleteProfiletoEmployee(PersonID,ProfileID ){
        return this.http.delete<any>(`${environment.apiUrl}/auth_user/${PersonID}/${ProfileID}`);
    }

    deleteAllProfileFromEmployee(PersonID){
        return this.http.delete<any>(`${environment.apiUrl}/auth_user/${PersonID}`);
    }

    deleteRole(RoleID){
        return this.http.delete<any>(`${environment.apiUrl}/auth_user/role/${RoleID}`);
    }

    deleteRoleFromProfile(RoleID, ProfileID){
        return this.http.delete<any>(`${environment.apiUrl}/auth_user/role/profile/${RoleID}/${ProfileID}`);
    }

    deleteProfile(ProfileID){
        return this.http.delete<any>(`${environment.apiUrl}/auth_user/profile/${ProfileID}`);
    }

}
