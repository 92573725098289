export class ConfigDB {
   static data = {
   settings: {
   layout_type: 'ltr',
   sidebar: {
      type: 'default',
      body_type: 'default'
   },
   sidebar_setting: 'border-sidebar',
   sidebar_backround: 'light-sidebar'
},
color: {
   layout_version: 'light',
   color: 'color-2',
   primary_color: '#038288',
   secondary_color: '#00dce8',
   mix_layout: 'dark-header-sidebar-mix'
},
router_animation: 'fadeIn'
}
}
