import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Leave, UBalance } from '../model';
import { Employee } from '../model/employee';
import { BusinessDivisionService } from 'src/app/shared/services/businessdivision.service';
import moment from 'moment';
import { Client } from '@microsoft/microsoft-graph-client';
import { ToastrService } from 'ngx-toastr';
import * as CryptoJS from "crypto-js";

@Injectable({ providedIn: 'root' })
export class LeaveService {
  constructor(private http: HttpClient,
    private businessdivisionService: BusinessDivisionService,
    private toastr: ToastrService,
  ) { }

  getMyLeaves(uId: number) {
    return this.http.get<Leave[]>(`${environment.apiUrl}/leaves/${uId}`);
  }

  getMyTeamLeaves(uId: number) {
    return this.http.get<Leave>(`${environment.apiUrl}/teamleaves/${uId}`);
  }

  getMyLeavesSum(uId: number) {
    return this.http.get<number>(`${environment.apiUrl}/leavessum/${uId}`);
  }

  getLeaveBalance(uId: number) {
    return this.http.get<UBalance>(`${environment.apiUrl}/leavebalance/${uId}`);
  }

  getLeave(LeaveID: number) {
    return this.http.get<Leave>(`${environment.apiUrl}/leave/${LeaveID}`);
  }

  saveNewLeave(PersonID, Approver, Detail, StartDate, EndDate, LeaveType, HalfDayStart, HalfDayEnd, RequestedDays, LeaveStatus, ApprovedProject) {
    return this.http.post<any>(`${environment.apiUrl}/leave/create`, {
      'PersonID': PersonID,
      'Approver': Approver,
      'Detail': Detail,
      'StartDate': StartDate,
      'EndDate': EndDate,
      'LeaveType': LeaveType,
      'HalfDayStart': HalfDayStart + "",
      'HalfDayEnd': "" + HalfDayEnd,
      'RequestedDays': RequestedDays,
      'LeaveStatus': LeaveStatus,
      'ApprovedProject': ApprovedProject + ""
    });
  }


  editLeave(leaveID, Approver, Detail, StartDate, EndDate, LeaveType, HalfDayStart, HalfDayEnd, RequestedDays, LeaveStatus, ApprovedProject) {
    return this.http.put<any>(`${environment.apiUrl}/editleave/${leaveID}`, {
      'Approver': Approver,
      'Detail': Detail,
      'StartDate': StartDate,
      'EndDate': EndDate,
      'LeaveType': LeaveType,
      'HalfDayStart': HalfDayStart + "",
      'HalfDayEnd': "" + HalfDayEnd,
      'RequestedDays': RequestedDays,
      'LeaveStatus': LeaveStatus,
      'ApprovedProject': ApprovedProject + ""
    });
  }

  deleteLeave(uId: number, LeaveID: number) {
    return this.http.delete<any>(`${environment.apiUrl}/leave/` + uId + "/" + LeaveID);
  }

  approveLeave(LeaveID: number, approve: boolean) {
    let body = [];
    return this.http.put<any>(`${environment.apiUrl}/approveLeave/` + LeaveID + "/" + approve, body);
  }


  // neue Routen für Urlaubsbudget, zukünft. Nutzung auch für Abwesenheitsliste

  getMyVacationBudget(PersonID: number) {
    return this.http.get<any>(`${environment.apiUrl}/myvacationbudget/${PersonID}`);
  }

  getMyLeavesv2(PersonID: number) {
    return this.http.get<Leave[]>(`${environment.apiUrl}/myleavedays/${PersonID}`);
  }


  sendCalendarEvent(leave) {



    try {

      return this.http.post<any>(`${environment.apiUrl}/msGraph/sendCalendarEvent/` + leave.LeaveID, {}).subscribe(result => {
        if (result.result === 'success') {
          this.toastr.success(result.message, 'Check');
        } else {
          this.toastr.success(result.message, 'Fehler');
        }
      });
    } catch {
      this.toastr.error('Kalendereintrag schlug Fehl', 'Fehler');
    }
  }




  deleteCalendarEvent(leave) {

    try {
        return this.http.post<any>(`${environment.apiUrl}/msGraph/deleteCalendarEvent/` + leave.LeaveID, {}).subscribe(result => {
          if (result.result === 'success') {
            this.toastr.success(result.message, 'Check');
          } else {
            this.toastr.success(result.message, 'Fehler');
          }
        });
      } catch {
        this.toastr.error('Löschung im Kalender schlug Fehl', 'Fehler');
      }

  }



}
