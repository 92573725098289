import { Injectable, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EmployeeProfile } from '../model/employeeprofile';
import { User, EmployeeRoles } from '../model';
import { EmployeeService } from './employee.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

// Menu
export interface Menu {
	path?: string;
	company?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	visible?: boolean;
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnInit {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public currentroles
	public RoleIDs
	public currentUser: User
	public IfMenu: boolean = false

	public VisibleAll: boolean = false
	public VisibleTeamleader: boolean = false
	public VisibleAdmin: boolean = false
	public VisibleGehalt: boolean = false
	public VisibleCPSManager: boolean = false
	public VisibleCRM: boolean = false
	public VisibleMyClient: boolean = false
	public VisibleAway: boolean = false
	public VisiblePR: boolean = false
	public VisibleSchool: boolean = false
	public VisibleStore: boolean = false
	public VisibleInfoDesk: boolean = false
	public VisibleRK: boolean = false
	rkpath;
	public VisibleTechAdmin: boolean = false
	public VisibleGB: boolean = false

	public VisibleAdminPayroll: boolean = false
	public VisibleAdminSchool: boolean = false
	public VisibleAdminUser: boolean = false
	public VisibleAdminStore: boolean = false
	public VisibleAdminExpenses: boolean = false
	public VisibleAdminOKR: boolean = false
	public VisibleOKR: boolean = false
	public VisibleAdminCandidate: boolean = false
	public VisibleCandidate: boolean = false
	public VisibleMP: boolean = false
	public VisibleFinance: boolean = false
	public VisibleContracts: boolean = false
	public VisibleValues: boolean = false
	public VisibleSales: boolean = false
	public VisibleTechnology: boolean = false
	public VisibleWhistleBlower: boolean = false
	public VisibleHRM: boolean = false
	public VisiblePeopleCulture: boolean = false
	private VisibleMentorPlus: boolean = false;
	public VisibleUserWithoutIntegration: boolean = false;
	public VisibleSolutionManager: boolean = false;
	public VisibleSkillmanagement: boolean = false;
	public VisibleSkillmanagementAdmin: boolean = false;

	public first
	public dataSource
	items
	MENUITEMS: Menu[]
	public Profile: EmployeeProfile


	constructor(private http: HttpClient) {

		this.onResize();

		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}

		if (environment.production) {
			this.rkpath = 'https://tst.grandega-access.de/rk'
		}
		else {
			this.rkpath = 'https://dev.grandega-access.de/rk'
		}

	}

	getItems(RoleIDs) {

		this.RoleIDs = RoleIDs;
		if (this.RoleIDs != null) {
			this.RoleIDs.forEach(element => {

				switch (element) {

					//TechAdmin
					case '107':
						this.VisibleTechAdmin = true;
						break;

					//Admin
					case '105':
						this.VisibleAdmin = true;
						break;

					//User
					case '103':
						this.VisibleAll = true;
						this.IfMenu = true;
						break;

					//Teamleiter
					case '104':
						this.VisibleTeamleader = true;
						break;

					//Gehalt
					case '111':
						this.VisibleGehalt = true;
						break;

					//CPS
					case '112':
						this.VisibleCPSManager = true;
						break;


					//Abwesenheiten
					case '115':
						this.VisibleAway = true;
						break;

					//CRM
					case '108':
						this.VisibleCRM = true;
						break;

					//Leistungsnachweise
					case '110':
						this.VisiblePR = true;
						break;

					//School
					case '113':
						this.VisibleSchool = true;
						break;

					//Store
					case '116':
						this.VisibleStore = true;
						break;



					//InfoDesk
					case '117':
						this.VisibleInfoDesk = true;
						break;

					//Reisekosten
					case '114':
						this.VisibleRK = true;
						break;

					//AdminUser
					case '118':
						this.VisibleAdminUser = true;
						break;

					//AdminPayroll
					case '121':
						this.VisibleAdminPayroll = true;
						break;

					//AdminExpenses
					case '124':
						this.VisibleAdminExpenses = true;
						break;

					//AdminSchool
					case '119':
						this.VisibleAdminSchool = true;
						break;

					//AdminStore
					case '120':
						this.VisibleAdminStore = true;
						break;

					//MyClient
					case '123':
						this.VisibleMyClient = true;
						break;

					//OKR
					case '125':
						this.VisibleOKR = true;
						break;

					//AdminOKR
					case '126':
						this.VisibleAdminOKR = true;
						break;

					//Managing Partner
					case '122':
						this.VisibleMP = true;
						break;

					//Mentor Plus
					case '133':
						this.VisibleMentorPlus = true;
						break;

					//Geschaeftsbereich Finance
					case '135':
						this.VisibleFinance = true;
						this.VisibleGB = true;
						break;

					//Geschaeftsbereich Vertragwesen
					case '136':
						this.VisibleContracts = true;
						this.VisibleGB = true;
						break;

					//grandega Values
					case '137':
						this.VisibleValues = true;
						break;


					//AdminCandidate
					//RoleID ändern
					case '138':
						this.VisibleGB = true;
						this.VisibleAdminCandidate = true;
						this.VisiblePeopleCulture = true;
						this.VisibleCandidate = true;
						break;

					case '141':
						this.VisibleGB = true;
						this.VisibleSales = true;
						break;

					case '148':
						this.VisibleGB = true;
						this.VisibleTechnology = true;
						break;

					//Bewerber
					case '127':
						this.VisibleCandidate = true;
						this.VisibleAll = true;
						break;

					//Whistleblower
					case '142':
						this.VisibleWhistleBlower = true;
						break;

					//HRM
					case '143':
						this.VisiblePeopleCulture = true;
						this.VisibleHRM = true;
						break;

					//HRM
					case '147':
						this.VisibleUserWithoutIntegration = true;
						break;
					//Solution-Manager
					case '149':
						this.VisibleGB = true;
						this.VisibleSolutionManager = true;
						break;

					//Skill-Manager
					case '150':
						this.VisibleSkillmanagement = true;
						break;

					//Skill-Manager
					case '151':
						this.VisibleSkillmanagement = true;
						this.VisibleSkillmanagementAdmin = true;
						break;
				}
			});
		}
		if (this.IfMenu) {
			this.MENUITEMS = [
				{
					path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', visible: this.VisibleAll || this.VisibleTechAdmin, active: false
				},
				// {
				// 	title: 'Leistungserfassung', icon: 'airplay', type: 'sub', active: false, visible: this.VisiblePR || this.VisibleTechAdmin, children: [
				// 		{ path: '/performance-records/new-pr', title: '+ hochladen', type: 'link', visible: this.VisiblePR || this.VisibleTechAdmin },
				// 		{ path: '/performance-records/my-pr', title: 'Meine Nachweise', type: 'link', visible: this.VisiblePR || this.VisibleTechAdmin }
				// 	]
				// },
				// {
				// 	path: '/my-payrolls', title: 'Gehaltsabrechnungen', icon: 'git-pull-request', type: 'link', visible: this.VisibleGehalt || this.VisibleTechAdmin
				// },
				{
					title: 'Abwesenheiten', icon: 'umbrella', type: 'sub', active: false, visible: this.VisibleAway || this.VisibleTechAdmin, children: [
						{ path: '/leave/leave-list', title: 'Übersicht', type: 'link', visible: this.VisibleAway || this.VisibleTechAdmin },
						{ path: '/leave/new-leave', title: 'Neue Abwesenheit', type: 'link', visible: this.VisibleAway || this.VisibleTechAdmin }
						//{ path: '/maintenance', title: 'Übersicht', type: 'link', visible: this.VisibleAway || this.VisibleTechAdmin },
						//{ path: '/maintenance', title: 'Neue Abwesenheit', type: 'link', visible: this.VisibleAway || this.VisibleTechAdmin }
					]
				},
				// {
				// 	path: '/covid/new', title: 'Covid Test', icon: 'thermometer', type: 'link', visible: (this.VisibleAll || this.VisibleTechAdmin) && !this.VisibleUserWithoutIntegration
				// },
				{
					title: 'Geschäftsbereich', icon: 'aperture', type: 'sub', active: false, visible: this.VisibleGB || this.VisibleTechAdmin, children: [
						{ path: '/geschaeftsbereich/finance/dashboard', title: 'Finance', type: 'link', visible: this.VisibleFinance || this.VisibleTechAdmin },
						// { path: '/geschaeftsbereich/technology/dashboard', title: 'Technologie', type: 'link', visible: this.VisibleTechnology || this.VisibleTechAdmin },
						{ path: '/geschaeftsbereich/mp/ma-care/mgmt-list', title: 'MP', type: 'link', visible: this.VisibleMP || this.VisibleTechAdmin },
						{
							title: 'People & Culture', icon: 'aperture', type: 'sub', active: false, visible: this.VisiblePeopleCulture || this.VisibleTechAdmin, children: [
								{ path: '/geschaeftsbereich/people-culture/candidates/list', title: 'Bewerber-Mgmt', type: 'link', visible: this.VisibleAdminCandidate || this.VisibleTechAdmin },
								{ path: '/geschaeftsbereich/people-culture/hrm/list', title: 'HRM', type: 'link', visible: this.VisibleHRM || this.VisibleTechAdmin }

							]
						},
						{ path: '/geschaeftsbereich/spotlight-manager/dashboard', title: 'Spotlight-Manager', type: 'link', visible: this.VisibleSolutionManager || this.VisibleTechAdmin },
						{
							title: 'Technologie', icon: 'aperture', type: 'sub', active: false, visible: this.VisibleAdminUser || this.VisibleTechnology || this.VisibleTechAdmin, children: [
								{ path: '/geschaeftsbereich/technology/dashboard', title: 'Assets', type: 'link', visible: this.VisibleTechnology || this.VisibleTechAdmin },
								{ path: '/admin/usr-mgmt/start', title: 'Benutzerverwaltung', type: 'link', visible: this.VisibleAdminUser || this.VisibleTechAdmin },
								{ path: '/geschaeftsbereich/technology/upload/documents/mondayVideo', title: 'MondayVideos', type: 'link', visible: this.VisibleAdminUser || this.VisibleTechAdmin }
							]
						},
						{ path: '/geschaeftsbereich/contracts/dashboard', title: 'Vertragswesen', type: 'link', visible: this.VisibleContracts || this.VisibleTechAdmin },
						{ path: '/geschaeftsbereich/sales/dashboard', title: 'Vertrieb', type: 'link', visible: this.VisibleSales || this.VisibleTechAdmin },

					]
				},
				{
					title: 'Geschäftsbereich V2', icon: 'aperture', type: 'sub', active: false, visible: this.VisibleGB || this.VisibleTechAdmin, children: [
						{ path: 'https://tst.grandega-access.de/v2/#/businessdivision/finance/dashboard', title: 'Finance', type: 'extLink', visible: this.VisibleFinance || this.VisibleTechAdmin },
						{ path: 'https://tst.grandega-access.de/v2/#/businessdivision/technology/dashboard', title: 'Technology', type: 'extLink', visible: this.VisibleTechAdmin },
						// { path: '/v2/#/businessdivision/contracts/dashboard', title: 'Vertragswesen', type: 'extLink', visible: this.VisibleContracts || this.VisibleTechAdmin },
						{ path: 'https://tst.grandega-access.de/v2/#/businessdivision/sales/dashboard', title: 'Vertrieb', type: 'extLink', visible: this.VisibleSales || this.VisibleTechAdmin },

					 ]
				},
				{
					path: 'https://grandega.sharepoint.com/sites/InfoDesk', title: 'grandega InfoDesk', visible: this.VisibleInfoDesk || this.VisibleTechAdmin, icon: 'info', type: 'extTabLink'
				},
				{
					path: 'https://grandega.sharepoint.com/sites/gwow', title: 'gWoW', visible: this.VisibleInfoDesk || this.VisibleTechAdmin, icon: 'info', type: 'extTabLink'
				},
				// {
				// 	path: '/values', title: 'grandega Mehrwerte', icon: 'star', type: 'link', visible: this.VisibleValues || this.VisibleTechAdmin
				// },
				{
					title: 'grandega OKR', icon: 'pie-chart', type: 'sub', active: false, visible: this.VisibleOKR, children: [
						{ path: '/okr/work/start', title: 'Dashboard', type: 'link', visible: this.VisibleOKR || this.VisibleTechAdmin },
						{ path: '/admin/okr/start', title: 'OKR Verwaltung', type: 'link', visible: this.VisibleAdminOKR || this.VisibleTechAdmin }
					]
				},
				{
					// title: 'grandega School', icon: 'book-open', type: 'sub', active: false, visible: this.VisibleSchool || this.VisibleTechAdmin, children: [
					// 	{ path: '/school/school-list', title: 'Schulungsliste', type: 'link', visible: this.VisibleSchool || this.VisibleTechAdmin },
					// 	{ path: '/school/school-booking', title: 'Meine Buchungen', type: 'link', visible: this.VisibleSchool || this.VisibleTechAdmin },
					// 	{ path: 'https://grandega.sharepoint.com/:f:/g/Eh1wrYfoAUxBkq0s6MjXrisBa08C_Q_1sR111hTkkwFzIw?e=0bUv9f', title: 'Bücherei', type: 'extTabLink', visible: this.VisibleSchool || this.VisibleTechAdmin },
					// 	{ path: '/admin/school-mgmt/training-list', title: 'Schoolverwaltung', type: 'link', visible: this.VisibleAdminSchool || this.VisibleTechAdmin }
					// ]

					//V2
					title: 'grandega School', icon: 'book-open', type: 'sub', active: false, visible: this.VisibleSchool || this.VisibleTechAdmin, children: [
						{ path: 'https://tst.grandega-access.de/v2/#/school/list', title: 'Schulungsliste', type: 'extLink', visible: this.VisibleSchool || this.VisibleTechAdmin },
						{ path: 'https://tst.grandega-access.de/v2/#/school/my-bookings', title: 'Meine Buchungen', type: 'extLink', visible: this.VisibleSchool || this.VisibleTechAdmin },
						{ path: 'https://grandega.sharepoint.com/:f:/g/Eg3ZeK1RyjhKnbFzu1mgfRYBnBKWjD2ppH-6r84XbLuz5A?e=9dxNpv', title: 'Bücherei', type: 'extTabLink', visible: this.VisibleSchool || this.VisibleTechAdmin },
						{ path: 'https://tst.grandega-access.de/v2/#/school/admin/list', title: 'Schoolverwaltung', type: 'extLink', visible: this.VisibleAdminSchool || this.VisibleTechAdmin }
					]

				},

				{
					path: '/cps/m/report', title: 'Gratifikation', icon: 'dollar-sign', type: 'link', visible: this.VisibleCPSManager || this.VisibleTechAdmin
				},
				{
					title: 'Mentor+', icon: 'users', type: 'sub', active: false, visible: this.VisibleMentorPlus || this.VisibleTechAdmin, children: [
						{ path: '/mentorplus/mentee-list', title: 'Meine Mentees', type: 'link', visible: this.VisibleMentorPlus || this.VisibleTechAdmin }

					]
				},
				{
					path: '/my-client/my-pr', title: 'MyClient', icon: 'monitor', type: 'link', visible: this.VisibleUserWithoutIntegration
				},
				{
					path: '/my-client/project-assignments', title: 'MyClient', icon: 'monitor', type: 'link', visible: (this.VisibleMyClient && !this.VisibleUserWithoutIntegration) || this.VisibleTechAdmin 
				},
				{
					path: 'https://pmcollaboration.sharepoint.com', title: 'PM Collaboration', visible: this.VisibleInfoDesk || this.VisibleTechAdmin, icon: 'git-pull-request', type: 'extTabLink'
				},
				{
					title: 'Reisekosten', icon: 'compass', type: 'sub', active: false, visible: this.VisibleRK || this.VisibleTechAdmin || this.VisibleAdminExpenses, children: [
						{ path: this.rkpath, title: 'Meine Reisen', visible: this.VisibleRK || this.VisibleTechAdmin, icon: 'info', type: 'extTabLink' },
						{ path: '/admin/expense-mgmt/expenses-checks/list', title: 'Reisekostenverwaltung', type: 'link', visible: this.VisibleAdminExpenses || this.VisibleTechAdmin }
					]
				},
				{
					title: 'Skillmanagement', icon: 'user', type: 'sub', active: false, visible: this.VisibleSkillmanagement || this.VisibleTechAdmin, children: [
						{ path: '/skillmanagement/user-detail', title: 'Meine Skills', type: 'link', visible: this.VisibleSkillmanagement || this.VisibleSkillmanagementAdmin || this.VisibleTechAdmin },
						{ path: '/skillmanagement/reports/skill-list', title: 'Skillsuche', type: 'link', visible: this.VisibleSkillmanagement || this.VisibleSkillmanagementAdmin || this.VisibleTechAdmin },
						{ path: '/skillmanagement/admin', title: 'Adminbereich', type: 'link', visible: this.VisibleSkillmanagementAdmin || this.VisibleTechAdmin }

					]
				},
				{
					title: 'Teamleitung', icon: 'users', type: 'sub', active: false, visible: this.VisibleTeamleader || this.VisibleTechAdmin, children: [
						{ path: '/teamleitung/member-list', title: 'Mein Team', type: 'link', visible: this.VisibleTeamleader || this.VisibleTechAdmin }

					]
				},
				{
					path: '/whistleblower/mgmt-new', title: 'Whistleblower-App', icon: 'eye', type: 'link', visible: this.VisibleWhistleBlower
				},
				// {
				// 	path: '/crm/dashboard', title: 'XRM', icon: 'grid', type: 'link', visible: this.VisibleCRM || this.VisibleTechAdmin
				// },
				{
					path: 'https://tst.grandega-access.de/v2/#/xrm/dashboard', title: 'XRM', icon: 'grid', type: 'extLink', visible: this.VisibleCRM || this.VisibleTechAdmin
					//path: '/maintenance', title: 'CRM', icon: 'grid', type: 'link', visible: this.VisibleCRM 
				},


				// {
				// 	title: 'Administration', icon: 'tool', type: 'sub', active: false, visible: this.VisibleAdmin, children: [
				// 		// { path: '/admin/it-asset-mgmt/dashboard', title: 'Assets', type: 'link', visible: this.VisibleTechAdmin },
				// 		//{ path: '/admin/new-payrolls', title: '+ Gehaltsabrechnungen', type: 'link', visible: this.VisibleAdminPayroll || this.VisibleTechAdmin },
				// 		//{ path: '/admin/product-mgmt', title: 'Produktverwaltung', type: 'link', visible: this.VisibleAdminStore || this.VisibleTechAdmin },
				// 	]
				// },


				// {
				// 	title: 'grandega Store', icon: 'shopping-cart', type: 'sub', active: false, visible: this.VisibleStore || this.VisibleTechAdmin, children: [
				// 		{ path: '/store/start', title: 'Start', type: 'link', visible: this.VisibleStore || this.VisibleTechAdmin },
				// 		{ path: '/store/add-cart', title: 'Einkaufswagen', type: 'link', visible: this.VisibleStore || this.VisibleTechAdmin }
				// 	]
				// },

				// {
				// 	title: 'Mein Bereich', icon: 'tool', type: 'sub', active: false, visible: this.VisibleCandidate || this.VisibleTechAdmin, children: [
				// 		{ path: '/candidates/candidates-docs', title: 'Meine Dokumente', type: 'link', visible: this.VisibleCandidate || this.VisibleTechAdmin }
				// 	]
				// },



			]

		};

		this.items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
		return this.items

	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	ngOnInit() {

	}

	isMobile() {

		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
			return true;
		}

		else if (/Chrome/i.test(navigator.userAgent)) {
			return false;
		}

		else {
			return false;
		}
	}

	checkIfLoggedIn() {
		return this.http.get<any>(`${environment.apiUrl}/checkIfLoggedIn`);
	}
}
