import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Cps as cps } from '../model';


@Injectable({ providedIn: 'root' })
export class CpsService {
  constructor(private http: HttpClient) { }

getMyCPS(email: string) {
  return this.http.get<cps>(`${environment.apiUrl}/cps/${email}`);
}

getBwa(){
  return this.http.get<any>(`${environment.apiUrl}/cps_bwa`);
}

}
