import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { componentsToColor } from 'pdf-lib';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../shared/services';

@Component({
  selector: 'app-ms-login',
  templateUrl: './ms-login.component.html',
  styleUrls: ['./ms-login.component.css']
})
export class MsLoginComponent implements OnInit {

  isloggedIn;
  returnUrl;

  constructor(
    private authService: MsalService,
    private broadcastService: BroadcastService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {

    if (JSON.parse(sessionStorage.getItem('returnUrl')) != null) {
      this.returnUrl = JSON.parse(sessionStorage.getItem('returnUrl'))

    } else {
      this.returnUrl = '/dashboard/default'

    }
    this.isloggedIn = this.authService.getAccount();

    var request = {
      scopes: ["user.readwrite", "user.readbasic.all", "profile", "Calendars.ReadWrite", "Files.ReadWrite.All"],
      loginHint: this.isloggedIn.preferred_username,
      extraQueryParameters: { domain_hint: 'organizations' }
    }

    this.authService.acquireTokenSilent(request).then((response) => {      
      const token = response.accessToken;
      this.getLaravel(response);

    }
    ).catch(function (error) {
    });

    this.authService.handleRedirectCallback((authError, response) => {

      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
    });


  }

  getLaravel(response) {

    this.authenticationService.azureLoginSuccess = true;

    this.authenticationService.azure_login(response).pipe(first())
      .subscribe(
        async data => {
          if (this.returnUrl != null) {
            if (this.returnUrl.substring(0, 4) == "/api") {
              await new Promise(resolve => setTimeout(resolve, 100)); // 0,3 sec
              window.location.href = "https://tst.grandega-access.de"+this.returnUrl;
            } else {
              this.router.navigate([this.returnUrl]);

            }
          }
        },
        error => {
        });
  }

}
