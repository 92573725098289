import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { MsLoginComponent } from './auth/ms-login/ms-login.component';
import { ToastrModule } from 'ngx-toastr';
import { MsalInterceptor, MsalModule } from "@azure/msal-angular";
import { AuthGuard } from './shared/guard/auth.guard';
import { CookieService } from 'ngx-cookie-service';
import { CookieLawModule } from "angular2-cookie-law";
//import { AngularFireModule } from "@angular/fire";
//import { AngularFireAuthModule } from "@angular/fire/auth";
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

import { HttpModule } from '@angular/http';

//import { ModalModule } from "angular-custom-modal";

import { ModalModule } from 'ngx-bootstrap/modal';

import { environment } from '../environments/environment';
//import { AngularFirestoreModule } from '@angular/fire/firestore';

import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDatePickerAdapter, CUSTOM_DATE_FORMATS } from './shared/services/date-adapter';

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';

import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MsLoginComponent
  ],
  imports: [
    HttpModule,
    ButtonModule,
    InputTextModule,
    ModalModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    BrowserModule,
    CookieLawModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgbModule,
    // MsalModule.forRoot({
    //   // stefan
    //   clientID: environment.client_id,
    //   redirectUri: environment.url+"/dashboard/default",
    //   authority: "https://login.microsoftonline.com/grandega.onmicrosoft.com"
    // }),
    MsalModule.forRoot({
      auth: {
        clientId: environment.client_id,
        redirectUri: environment.url + "/dashboard/default",
        authority: "https://login.microsoftonline.com/grandega.onmicrosoft.com"
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
    }, {
      protectedResourceMap: [
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ['https://graph.microsoft.com/v1.0/me/photos/120x120/$value', ['user.read']],
        ['https://graph.microsoft.com/v1.0/me/calendar', ["Calendars.ReadWrite"]],
        ['https://graph.microsoft.com/v1.0/me/calendarview*', ['Calendars.ReadWrite']],
        ['https://graph.microsoft.com/v1.0/me/drive/recent', ['Files.Read']],
        //['https://graph.Microsoft.com/v1.0/me/calendar/events/*', ['Calendars.ReadWrite']],
        //['https://graph.microsoft.com/v1.0/users/marvin.equit@grandega.de/events', ['User.ReadWrite.All', 'Directory.ReadWrite.All', 'Calendars.ReadWrite']],
        ['https://graph.microsoft.com/v1.0/users', ['User.ReadWrite.All', 'Directory.ReadWrite.All', 'Calendars.ReadWrite']],
      ]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),

  ],
  providers: [
    AuthGuard,
    CookieService,
    { provide: DateAdapter, useClass: CustomDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
