
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, GraphService, SchoolService } from '../../services';
import { Router } from '@angular/router';
import { User, EmployeeProfile, UBalance } from '../../model';
import { Subscription } from 'rxjs';
import { EmployeeService } from '../../services/employee.service';
import { LeaveService } from '../../services/leave.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

var body = document.getElementsByTagName("body")[0];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  currentUser: User;
  currentroles: any;
  RoleIDs: any;
  currentUserSubscription: Subscription;
  currentRolesSubscription: Subscription;
  points:number;
  ubalance: UBalance;
  trainings;
  public menuItems: Menu[];
  public items: Menu[];
  public searchResult: boolean = false;
  public searchResultEmpty: boolean = false;
  public openNav: boolean = false
  public right_sidebar: boolean = false
  public text: string
  public isOpenMobile: boolean = false

  profileImg: SafeResourceUrl;
  
  @Output() rightSidebarEvent = new EventEmitter<boolean>();
  

  constructor(
    private schoolService: SchoolService, 
    private authenticationService: AuthenticationService,
    public navServices: NavService,
    private translate: TranslateService,
    private graphService: GraphService,
    private router: Router,
    private employeeService: EmployeeService,
    private leaveService: LeaveService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private authService: MsalService,
    ){

      this.getProfileImg();
      sessionStorage.removeItem('returnUrl');
      this.currentUser = this.authenticationService.currentUserValue;

      if(this.currentUser != null && this.currentUser.AccountType != 'bewerber'){
        if(JSON.parse(sessionStorage.getItem('actualEmployee')) == null){
      this.employeeService.getById(this.currentUser.PersonID).subscribe(employee=>{
        sessionStorage.setItem('actualEmployee',JSON.stringify(employee[0]));        
        });
      }
    }
    
    if(JSON.parse(sessionStorage.getItem('roleID'))== null && this.currentUser != null){
      this.currentRolesSubscription =this.employeeService.getRoles(this.currentUser.PersonID).subscribe(roles => {
        this.currentroles = roles;
        this.RoleIDs = this.currentroles.map(x=>x.RoleID);
        sessionStorage.setItem('roleID',JSON.stringify(this.RoleIDs));

        this.navServices.getItems(this.RoleIDs).subscribe(menuItems => {
          this.items = menuItems
        });
      
      });
    } else{
        this.RoleIDs=sessionStorage.getItem('roleID');
        if(this.RoleIDs != null){
      this.navServices.getItems(JSON.parse(this.RoleIDs)).subscribe(menuItems => {
        this.items = menuItems
      });
    }
      
    }

    if( this.currentUser != null && this.currentUser.AccountType != 'bewerber'){
    // this.setTrainingPoints();
    // this.setLeaveBalance();
    }
     // translate.setDefaultLang('en');
     this.checkLogin();
  }

  ngOnDestroy() {
    this.removeFix();
  }
  
//   setTrainingPoints(){
//     this.currentUser= this.authenticationService.currentUserValue;
//     this.schoolService.getBookedTrainings(this.currentUser.PersonID).subscribe(bookedtrainings => {
//       this.trainings = bookedtrainings; 
  
     
//     try {
//       this.points=12;
//        for (let i = 0; i < Object.keys(this.trainings).length; i++) {
        
//         if(this.trainings[i].BookingStatus != "abgelehnt" && this.trainings[i].Created>'2021-01-01 00:00:00.000'){
//             this.points= this.points - +this.trainings[i].TrainingPoint;     

//          }
//     }
//     sessionStorage.setItem('actualTrainingPoints',JSON.stringify(this.points));
//   }
  
//   catch{

// }
// });
// }

// public setLeaveBalance(){
//   this.leaveService.getLeaveBalance(this.currentUser.PersonID).subscribe(ubalance => {
//     sessionStorage.setItem('actualLeaveBalance',JSON.stringify(ubalance[0]));
//   });
// }

  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  collapseSidebar() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  public changeLanguage(lang) {
    this.translate.use(lang)
  }

  searchTerm(term: any) {
    term ? this.addFix() : this.removeFix();
    if (!term) return this.menuItems = [];
    let items = [];
    term = term.toLowerCase();
    this.items.filter(menuItems => {
      if (menuItems.title.toLowerCase().includes(term) && menuItems.type === 'link') {
        items.push(menuItems);
      }
      if (!menuItems.children) return false
      menuItems.children.filter(subItems => {
        if (subItems.title.toLowerCase().includes(term) && subItems.type === 'link') {
          subItems.icon = menuItems.icon
          items.push(subItems);
        }
        if (!subItems.children) return false
        subItems.children.filter(suSubItems => {
          if (suSubItems.title.toLowerCase().includes(term)) {
            suSubItems.icon = menuItems.icon
            items.push(suSubItems);
          }
        })
      })
      this.checkSearchResultEmpty(items)
      this.menuItems = items
    });
  }

  checkSearchResultEmpty(items) {
    if (!items.length)
      this.searchResultEmpty = true;
    else
      this.searchResultEmpty = false;
  }

  addFix() {
    this.searchResult = true;
    body.classList.add("offcanvas");
  }

  removeFix() {
    this.searchResult = false;
    body.classList.remove("offcanvas");
    this.text = "";
  }

  logout() {
    this.authenticationService.logout();
}
ngOnInit() {
}

getProfileImg() {
  this.http
    .get('https://graph.microsoft.com/v1.0/me/photos/120x120/$value', {
      headers: { 'Content-Type': 'image/*' },
      responseType: 'arraybuffer',
    })
    .toPromise()
    .then(
      (data) => {
        const TYPED_ARRAY = new Uint8Array(data);
        // converts the typed array to string of characters
        const STRING_CHAR = String.fromCharCode.apply(null, TYPED_ARRAY);

        //converts string of characters to base64String
        let base64String = btoa(STRING_CHAR);

        //sanitize the url that is passed as a value to image src attrtibute
        this.profileImg = this.sanitizer.bypassSecurityTrustUrl(
          'data:image/*;base64, ' + base64String
        );
      },
      (err) => {
        this.profileImg = '../assets/images/user/1.jpg';
      }
    );
}

isloggedIn
checkLogin() {

  var currentTimeInSeconds=Math.floor(Date.now()/1000);

  this.isloggedIn = this.authService.getAccount();

  var exp =null;

  if(this.isloggedIn != null)
   exp = this.isloggedIn.idToken.exp;


  if (this.isloggedIn == null || exp<currentTimeInSeconds) {

    this.logout()
  }
}

}
