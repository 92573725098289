import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService } from '../../shared/services';
import { HttpClient } from "@angular/common/http";

import { BroadcastService } from "@azure/msal-angular";
import { MsalService } from "@azure/msal-angular";
import { environment } from "../../../environments/environment";

import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/shared/services/login.service';



@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  env = environment.version;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  email: string;

  test;

  //for msal
  public userInfo: any = null;
  public isIframe: boolean;
  isloggedIn;

  constructor(
    private http: HttpClient,
    private broadcastService: BroadcastService,
    private authService: MsalService,

    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private loginService: LoginService,
  ) {

    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

  }

  async ngOnInit() {

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard/default';
    sessionStorage.setItem('returnUrl', JSON.stringify(this.returnUrl));
    this.checkoutAccount();

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.email = '';
    
    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
    });
    
    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      //  lil hack for not having login phases
      this.authenticationService.azureLoginSuccess = true;

      this.authenticationService.azure_login(payload).pipe(first())
        .subscribe(
          data => {
              this.router.navigate([this.returnUrl]);
          },
          error => {
            this.alertService.error(error);
            this.loading = false;
          });
    });

  }


  checkoutAccount() {

    var currentTimeInSeconds=Math.floor(Date.now()/1000);

    this.isloggedIn = this.authService.getAccount();

    var exp = this.isloggedIn.idToken.exp;

  
    if (this.isloggedIn && exp>currentTimeInSeconds) {

      this.router.navigate(['/auth/ms-login']);

    }
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.alertService.error("Formulardaten ungültig.");
      return;
    }

    this.loading = true;
    // client side hashing not done because of SSL
    // let hashedPw = bcrypt(this.f.password.value);
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        user => {

            this.router.navigate([this.returnUrl]);
        },
        error => {
          this.alertService.error("Formulardaten ungültig.");
          this.loading = false;
        });
  }

  azure_login() {

      this.authService.loginRedirect({
        scopes: ["user.readwrite", "user.readbasic.all", "profile", "Calendars.ReadWrite", "Files.ReadWrite.All"]
      });

    this.authenticationService.azureLoginSuccess = true;

  }

  azure_logout() {
    this.loginService.setUserLoggedIn(false);
    this.authService.logout();
  }

  resend(valid: boolean) {
    if (valid) {
      this.http.post<any>(`${environment.apiUrl}/users/forgot`, { 'email': this.email }).subscribe(data => {
        if (data.result === 'success') {
          this.alertService.success("Ihr Passwort wurde zurückgesetzt und eine Email versandt.");
        }
      });
    } else {
      this.alertService.error("Email ungültig.")
    }
  }

}
