import { Routes, RouterModule } from '@angular/router';

export const full: Routes = [
  {
    path: 'error',
    loadChildren: () => import('../../pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
  },
  {
    path: 'landing-page',
    loadChildren: () => import('../../pages/landing-pages/landing-pages.module').then(m => m.LandingPagesModule),
  },
  {
    path: 'comingsoon',
    loadChildren: () => import('../../pages/coming-soon/coming-soon.module').then(m => m.ComingSoonModule),
  },
  {
    path: 'forbidden',
    loadChildren: () => import('../../pages/forbidden/forbidden.module').then(m => m.ForbiddenModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('../../pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  }
  
]