import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Cps as cps } from '../model';


@Injectable({ providedIn: 'root' })
export class BusinessDivisionService {
  constructor(private http: HttpClient) { }

getFakturaDetail(PSPElementeID: number, PRecordID: number) {
  return this.http.get<any>(`${environment.apiUrl}/financeInvoice/detail/`+PSPElementeID+"/"+PRecordID);
}

getInvoices() {
  return this.http.get<any>(`${environment.apiUrl}/financeInvoices`);
}

getActivePSPElements() {
  return this.http.get<any>(`${environment.apiUrl}/financeactivepspelements`);
}

getDBMargeData() {
  return this.http.get<any>(`${environment.apiUrl}/dbMargeData`);
}

getDBMargeByPersonID(PersonID) {
  return this.http.get<any>(`${environment.apiUrl}/dbMargeData/person/`+PersonID);
}

getQualificationLevels() {
  return this.http.get<any>(`${environment.apiUrl}/qualificationlevels`);
}

getQualificationLevels2() {
  return this.http.get<any>(`${environment.apiUrl}/qualificationlevels2`);
}

getContractsByType(mainTyp, subType) {
  return this.http.get<any>(`${environment.apiUrl}/contracts/`+mainTyp+"/"+subType);
}

getContractCategorys() {
  return this.http.get<any>(`${environment.apiUrl}/contractCategorys`);
}

getContractMainCategorys() {
  return this.http.get<any>(`${environment.apiUrl}/contractMainCategorys`);
}

getContractsByID(contractID,mainTyp, subType) {
  return this.http.get<any>(`${environment.apiUrl}/contractByID/`+contractID+"/"+mainTyp+"/"+subType);
}

getContractData() {
  return this.http.get<any>(`${environment.apiUrl}/contractData`);
}

getMainContracts() {
  return this.http.get<any>(`${environment.apiUrl}/mainContracts`);
}

getProlongationsInProject(PersonID, ProjectID) {
  return this.http.get<any>(`${environment.apiUrl}/prolongationInProject/`+PersonID+"/"+ProjectID);}

deleteContractDoc(ContractDocID: number, ContractMainID: number){
  return this.http.delete<any>(`${environment.apiUrl}/contractdatafiles/`+ContractDocID+"/"+ContractMainID);
}

deleteContract(ContractMainID: number){
  return this.http.delete<any>(`${environment.apiUrl}/contract/`+ContractMainID);
}

getAPPAccessToken() {
  return this.http.get<any>(`${environment.apiUrl}/application/accessToken/azWEHsffajQEGmwqeGSh912747JR132tSAEFuheAwrf101hasAffu2nase9aawH2W`);
}

}
